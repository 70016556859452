<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" class="text-center black--text">
        <p>
          <strong>For Enna Alouette. From Aloupeeps.</strong>
        </p>
        <p>
          Management: Ennacord staff<br />
          Programming, Site header message: jetrico<br />
          Header &amp; Background Art: Hel<br />
          Aloupeeps on Cards: Frank Akanoru<br />
          Timeline committee: koshka-sova, Professor Nobu<br />
          Popup Video: MB, Music: Vince, Modal: AlphaBud<br />
        </p>
        <p>
          <strong>Country Roads Acapella</strong><br />
          Video: Soul Jam<br />
          Arrangement: Soul Jam<br />
          Alternate Lyrics: Soul Jam, guGGy<br />
          Mix: Soul Jam<br />
          Vocals: Acerola Heart, Binns, Crono, DarkDisasterKid, Simpy Dippy,<br />
            Domo Espresso, Drumfeelies, hiya! kk, IcyMeerkat, Jo-さん, koshka-sova, Norenche,<br />
            Professor Nobu, Ralphy, Sasu Crow, Senno, Soul Jam, WhiteGuyGraal, zuiKatsu<br />
          Artwork: MinniJay, Professor Nobu, Soul Jam<br />
        </p>
        <p>
          <strong>Art Quilt</strong><br />
          Base Design: Garfield Factory<br />
          Artists (in order, top-bottom, left-right):<br />
            reteach, Professor Nobu, Jo-さん, Erin, Drumfeelies,<br />
            lashe w, wax apple, Sorekasho, oja, Toast,<br />
            mirrorline, Garfield Factory, Hel, Frank Akanoru, Hexvati,<br />
            jetrico, Voldox, MinniJay, no1, sojuice
        </p>
        <p>
          <strong>Ethyria Portal</strong>
          (<a href="https://ethyria6m.fromyour.fans" target="_blank">link</a>)<br />
          Programming: AlphaBud<br />
          Character Artworks: MinniJay, Hel<br />
          Background, Word Art: Professor Nobu<br />
        </p>
        <p>
          <strong>A production of the Enna Alouette Fan Server</strong><br />
          Project Manager: Domo Espresso<br />
          Server Owner: MiikeMQ<br />
          Tech Lead: jetrico<br />
          and all Aloupeeps who celebrate these milestones with Enna and Ethyria!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: [],
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>

</style>
