<template>
  <v-container class="section-grid">
    <v-row no-gutters class="mb-8">
      <v-col cols="12">
        <h1 class="yellow--text text--darken-3 text-center pa-0 ma-0">
          <img
            src="../assets/img/section-rewind.png" height="90"
            alt="Rewind" />
        </h1>
        <h2 class="grey--text text--darken-3 text-center pa-0 ma-0 white-glow">
          Looking back to the fun and amazing moments we had with Enna
        </h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left text-right">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/grKqMWfH22c"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right black--text">
          <div class="rewind-texts">
            <h2 class="mb-4">October 2021</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                Perfect exhibit of Ethyria's early dynamics,
                with a focus on Enna being a menace to her genmates (Nina especially).
                In this heavenly house we do a little trolling, so it seems.
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                The way Nina loses her shit with how kusogaki Enna is,
                it's something that never happened to this extent before and beyond this moment.
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left black--text text-right">
          <div class="rewind-texts">
            <h2 class="mb-4">November 2021</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                Enna loves Ooga Boogas as much as us,
                and i never saw a person actually using Ooga Boogas before her. Fascinating.
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                Highlights from the RE7 streams, with the now-legendary ooga boogas.
                The phrase even made it to the membership emotes (thanks roomie!).
              </div>
            </div>
          </div>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right text-left">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/yAZCKfEKM5I"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left text-right">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/Eq6j0qaaYNA"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right black--text">
          <div class="rewind-texts">
            <h2 class="mb-4">December 2021</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                We can't NOT include life advice from Enna.
                Some of her best moments come from these lovely zatsudan streams,
                with which I am positive many other listeners agree.
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                It's a really pertinent topic that
                i'm sure is being lived by a lot of people right now.
                Enna's zatsus are so wholesome.
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left black--text text-right">
          <div class="rewind-texts">
            <h2 class="mb-4">January 2022</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                This was a incredible moment for the Songbird,
                where even "non-anime fan" music nerds said they were impressed with the quality.
                (Yes. They had to listen to this.)
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                Enna's been doing a capella since her first week,
                and man she delivers Every Time! She loves harmonizing,
                and we love hearing her harmonize. It is only fair to highlight her singing.
              </div>
            </div>
          </div>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right text-left">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/2txZlmtpYDM"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left text-right">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/UceTcVw-XzM"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right black--text">
          <div class="rewind-texts">
            <h2 class="mb-4">February 2022</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                Ah, yes. "Never listen to chat" *wink wonk*.
                Screams, curses, and most importantly, Betrayal.
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                It was legit one of the most desperate
                and genuine screams of despair that i ever heard.
                In this day, chat wasn't playing around.ヾ( •́д•̀ ;)ﾉ
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left black--text text-right">
          <div class="rewind-texts">
            <h2 class="mb-4">March 2022</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                "HELLO EVERYBODY , I AM THE GREAT ROSEMI LOVELOCK-SAMA!
                WOOO~~~" HAHAHAHA THE IMPRESSION IS SO FUNNY! I CAN'T-
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                The offline collab! Enna does a good Rosemi impression,
                if we only count her going "oh!" and focusing her eyes.
                For real though, the collab was a blast!
              </div>
            </div>
          </div>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right text-left">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/aNDMAkA5mn8"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="rewind-item">
      <v-col>
        <div class="rewind-left text-right">
          <iframe title="feature-video" class="rewind-frame"
            width="640" height="360"
            src="https://www.youtube.com/embed/aL9ktaEDmEg"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="rewind-center">
          <div class="rewind-dot"></div>
        </div>
        <div class="rewind-right black--text">
          <div class="rewind-texts">
            <h2 class="mb-4">April 2022</h2>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                This is TOO GOOD not to be mentioned.
                The day aloupeeps became clowns. Jebaited left and right.
                Why are we still here? Just to suffer?
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-text text-body-1 pa-2">
                "Oh no, it's so late, i can't believe
                i'll miss the first english karaoke stream (｡•́︿•̀｡)"
                Then i wake up in the next day...
                and all the aloupeeps were still processing what just happened.
                We are still proud Alouclowns.
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
.rewind-item {
  position:relative;
  height:400px;
  .rewind-left {
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    width:50%;
    padding-right:50px;
    .rewind-texts {
      right:50px;
    }
  }
  .rewind-center {
    width:0px;
    background:#fcc;
    position:absolute;
    left:50%;
    top:0;
    bottom:0;
    margin-left:-10px;
    border-left:4px solid #131313;
    .rewind-dot {
      width:30px;
      height:30px;
      background:#131313;
      border-radius:20px;
      border:4px solid #131313;
      position:absolute;
      top:50%;
      margin-left:-17px;
    }
  }
  .rewind-right{
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    width:50%;
    padding-left:50px;
    .rewind-texts {
      left:50px;
    }
  }
  .rewind-frame {
    margin:20px;
    width:640px;
    height:360px;
    max-width:90%;
  }
  .rewind-texts {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
  }
}

.card {
  position:relative;
  width:100%;
  background:rgba(255, 255, 255, 0.3);
  padding:5px 20px;
  border-radius: 10px;
  .card-name {
    color:#343c75;
    font-weight:bold;
  }
  .card-text {
    color:#000;
    overflow-y:auto;
    overflow-x:hidden;
  }
}


</style>
